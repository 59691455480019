@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap');

body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f8f8f8; */
  background-color: #575757;
  font-size: 18px;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: sans-serif;
  
}

h1, h5, h3, Link{
    font-family: "Josefin Sans", sans-serif;
    text-decoration: none;
    font-weight: 500;
    color: white;
} 

.nav{
    color: white;
    padding-top: 20px;
    margin: 5px;
    text-align: center;
}

.container-joke{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}


.header-joke{
    height: 87px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    /* background-color: #bad3db; */
    background-color: #0c0c0c;

}

.header-text{
    color: white;
}

.container_body{
    width: 100%;
    height:auto;
    background-color: #0c0c0c;
    margin: 20px;
    color: white;
    padding: 30px;
    border-radius: 10px;
}

button{
    width: 300px;
    height: 50px;
    margin: 30px;
    border-radius: 10px;
    /* flex-direction: column; */
    font-size: 20px;
    background-color: #575757;
    color: white;
    cursor: pointer;
}


.logo{
   width: 80px;
   height: 80px;
   
}

.content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 30px;
}



.content-btn{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    
    
}

label{
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.footer{
    width: 100%;
    height: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0c0c;
    padding: 10px;
}

.footer-text{
    color: white;
}


@media only screen and (max-width: 1024px) {
    
    .container-joke{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
    }

    .header-text{

        color: white;
    }

}  
